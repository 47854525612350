import React, { useCallback } from 'react';

import dynamic from 'next/dynamic';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import { TrackViewDimension } from '@marty-js/design/src/molecules/download/modal';
import type { Cell } from '../../types';
import { EmbedSlateContent } from '../slate.content';

const ProductCardArticle = dynamic(() => import('@marty-js/design/src/molecules/article/product-card'));

interface ClubicContainerProps {
  cell: Cell;
}

export function ProductCard({ cell }: ClubicContainerProps) {
  const [trackEvent] = useTrackEvent();

  const title = cell?.content?.state?.data?.title;

  const mainCtaInfos = cell?.content?.state?.data?.mainCtaInfos;

  const eventCta = useCallback(
    (shopName?: string, price?: string, position?: string) => {
      const userToken = localStorage.getItem('actionToken');
      const trackEventDimension: TrackViewDimension[] = [{ id: 'dimension1', value: mainCtaInfos?.contentType }];

      trackEvent({
        variables: {
          userToken,
          eventCategory: 'BestPickPage',
          eventName: 'click_comparatif',
          actionName: 'click_comparatif',
          eventValue: JSON.stringify({
            product: title,
            price: price ?? mainCtaInfos?.price,
            marchand: shopName ?? mainCtaInfos?.shopName,
            position: position ?? null,
          }),
          customDimensions: trackEventDimension,
          customVariables: [],
          url: document.location.href,
        },
      });
    },
    [mainCtaInfos?.contentType, mainCtaInfos?.price, mainCtaInfos?.shopName, title, trackEvent],
  );

  return cell.content?.state?.data ? (
    <ProductCardArticle cell={cell} eventCta={eventCta} SlateContent={EmbedSlateContent} />
  ) : null;
}

export default ProductCard;

export const PRODUCT_CARD_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/product-card';
